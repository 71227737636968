import axios from 'axios';

export default {
  getDataImportSession(dataImportSessionId) {
    return axios.get('/api/EventsMatch/GetDataImportSession', {
      params: {
        dataImportSessionId
      }
    });
  },
  getEventsMatch(dataImportSessionId) {
    return axios.get('/api/EventsMatch/GetEventsMatch', {
      params: {
        dataImportSessionId
      }
    });
  },
  getRelatedEvents(eventCardId) {
    return axios.get('/api/EventsMatch/GetRelatedEvents', {
      params: {
        eventCardId
      }
    });
  },
  matchUserAndPublicEvent(dataImportSessionId, userEventCardId, publicEventCardId) {
    return axios.post('/api/EventsMatch/MatchUserAndPublicEvent', { dataImportSessionId, userEventCardId, publicEventCardId });
  },
  breakMapping(dataImportSessionId, userEventCardIds) {
    return axios.post('/api/EventsMatch/BreakMapping', { dataImportSessionId, userEventCardIds });
  },
  acceptMatching(dataImportSessionId, eventsMatchList) {
    return axios.post('/api/EventsMatch/AcceptMatching', { dataImportSessionId, eventsMatchList });
  }
};

