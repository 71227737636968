<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="60%"
    height="50%"
    center
    z-index="999"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Организационные причины
    </div>
    <div class="m-2">
      <div style="display: inline-block;">
        <!--        <span class="mr-4">Отобразить:</span>-->
        <el-checkbox @change="applyFilter" v-model="filter.showSuccess">Успешно сопоставленные</el-checkbox>
        <el-checkbox @change="applyFilter" v-model="filter.showConflict">Конфликтные</el-checkbox>
        <el-checkbox @change="applyFilter" v-model="filter.showNew">Новые</el-checkbox>
        <el-checkbox @change="applyFilter" v-model="filter.showDivergence">Расхождение</el-checkbox>
      </div>
      <div style="display: inline-block; float: right">
        <el-button :disabled="ignoreMappingDisabled()" class="p-0 mr-2" @click="ignoreMapping" type="text" icon="el-icon-circle-close">Игнорировать</el-button>
        <el-button :disabled="matchManuallyDisabled()" class="p-0 mr-2" @click="matchManually" type="text" icon="el-icon-connection">Сопоставить вручную</el-button>
        <el-button :disabled="breakMappingDisabled()" class="p-0 mr-2" @click="breakMapping" type="text" icon="el-icon-remove-outline">Разорвать сопоставление</el-button>
      </div>
    </div>
    <el-table style="width: 100%;"
              class="compare-modal-table"
              v-loading="loading"
              :data="filteredItems"
              :row-class-name="getRowClassName"
              height="50vh"
              @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="reviewStatus !== reviewStatuses.reviewed && reviewStatus !== reviewStatuses.ignored"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="matchStatus"
        label=""
        width="50" fixed>
        <template slot-scope="scope">
          <div style="text-align: center">
            <i v-if="scope.row.matchStatus === matchStatuses.divergence"
               style="font-size: 1.4rem"
               class="event-compare-divergence">
              <QuestionIcon/>
            </i>
            <i v-else-if="scope.row.matchStatus === matchStatuses.conflict"
               style="font-size: 1.4rem"
               class="el-icon-warning-outline event-compare-conflict" />
            <i v-else-if="scope.row.matchStatus === matchStatuses.newRow"
               style="font-size: 1.4rem"
               class="el-icon-circle-plus-outline event-compare-new" />
            <i v-else-if="scope.row.matchStatus === matchStatuses.success"
               style="font-size: 1.4rem"
               class="el-icon-circle-check event-compare-success" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Код" width="200" fixed>
        <el-table-column
          prop="code"
          label="Текущее" width="100" fixed>
          <template slot-scope="scope">
            <span v-if="scope.row.publicStaffCulpability">
              {{ scope.row.publicStaffCulpability.staffCulpabilityCode }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="code"
          label="Импорт" width="100" fixed>
          <template slot-scope="scope">
            <span v-if="scope.row.userStaffCulpability">
              {{ scope.row.userStaffCulpability.staffCulpabilityCode }}
            </span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Код категории персонала">
        <el-table-column prop="publicStaffCulpability.staffCategoryCode"
                         label="Текущее"
                         min-width="10">
          <template slot-scope="scope">
            <div
              v-if="scope.row.publicStaffCulpability"
              @click="denySingleManualMatch(scope.row, 'staffCategoryCode')"
              :class="[ getParamMatchClass(scope.row, 'staffCategoryCode'), getPublicSingleManualMatchClass(scope.row, 'staffCategoryCode')]"
            >
              <template v-if="scope.row.eventTimeManualMatched">
                {{ scope.row.backupData.staffCategoryCode }}
              </template>
              <template v-else>
                {{ scope.row.publicStaffCulpability.staffCategoryCode }}
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userEventTime"
                         label="Импорт"
                         min-width="10"
                         class-name="manual-match">
          <template slot-scope="scope">
            <div
              v-if="scope.row.userStaffCulpability"
              @click="applySingleManualMatch(scope.row, 'staffCategoryCode')"
              :class="[ getParamMatchClass(scope.row, 'staffCategoryCode'), getUserSingleManualMatchClass(scope.row, 'staffCategoryCode') ]"
            >
              {{ scope.row.userStaffCulpability.staffCategoryCode }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Наименование организации">
        <el-table-column prop="publicStaffCulpability.organizationName"
                         label="Текущее"
                         min-width="10">
          <template slot-scope="scope">
            <div
              v-if="scope.row.publicStaffCulpability"
              @click="denySingleManualMatch(scope.row, 'organizationName')"
              :class="[ getParamMatchClass(scope.row, 'organizationName'), getPublicSingleManualMatchClass(scope.row, 'organizationName')]"
            >
              <template v-if="scope.row.eventTimeManualMatched">
                {{ scope.row.backupData.organizationName }}
              </template>
              <template v-else>
                {{ scope.row.publicStaffCulpability.organizationName }}
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userEventTime"
                         label="Импорт"
                         min-width="10"
                         class-name="manual-match">
          <template slot-scope="scope">
            <div
              v-if="scope.row.userStaffCulpability"
              @click="applySingleManualMatch(scope.row, 'organizationName')"
              :class="[ getParamMatchClass(scope.row, 'organizationName'), getUserSingleManualMatchClass(scope.row, 'organizationName') ]"
            >
              {{ scope.row.userStaffCulpability.organizationName }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
// import cloneDeep from 'lodash.clonedeep';
import cloneDeep from 'lodash.clonedeep';
import eventCompare from '@/mixins/eventCompare';
import QuestionIcon from '@/assets/icons/question-icon.svg';

export default {
  name: 'StaffCulpabilityMatchModal',
  props: ['value', 'items', 'reviewStatus'],
  components: { QuestionIcon },
  mixins: [eventCompare],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
 /* watch: {
    reviewStatus: {
      handler(newVal) {
        if (newVal === this.reviewStatuses.ignoredNotAccepted) {
          this.items.forEach((item) => {
            item.reviewStatus = this.reviewStatuses.ignoredNotAccepted;
          });
        }
        if (newVal === this.reviewStatuses.ignored) {
          this.items.forEach((item) => {
            item.reviewStatus = this.reviewStatuses.ignored;
          });
        }
      }
    }
  }, */
  data() {
    return {
      loading: false,
      filter: {
        showSuccess: true, showConflict: true, showDivergence: true, showNew: true
      },
      selectedItems: [],
      filteredItems: [],
    };
  },
  mounted() {
    this.filteredItems = this.items;
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    handleSelectionChange(val) {
      this.selectedItems = val;
    },
    applyFilter() {
      let tmp = this.items;

      if (!this.filter.showSuccess) {
        tmp = tmp.filter((r) => r.matchStatus !== this.matchStatuses.success);
      }
      if (!this.filter.showDivergence) {
        tmp = tmp.filter((r) => r.matchStatus !== this.matchStatuses.divergence);
      }
      if (!this.filter.showNew) {
        tmp = tmp.filter((r) => r.matchStatus !== this.matchStatuses.newRow);
      }
      if (!this.filter.showConflict) {
        tmp = tmp.filter((r) => r.matchStatus !== this.matchStatuses.conflict);
      }

      this.filteredItems = tmp;
    },
    getRowClassName({ row }) {
      return this.getClassByReviewStatus(row.reviewStatus);
    },
    getClassByReviewStatus(status) {
      if (status === this.reviewStatuses.ignoredNotAccepted) {
        return  'event-compare-ignored';
      }
      if (status === this.reviewStatuses.ignored) {
        return  'event-compare-ign-reviewed';
      }
      if (status === this.reviewStatuses.reviewed) {
        return  'event-compare-reviewed';
      }

      return '';
    },
    getPublicSingleManualMatchClass(row, param) {
      if (this.reviewStatus === this.reviewStatuses.reviewed || this.reviewStatus === this.reviewStatuses.ignored || row.matchStatus === this.matchStatuses.newRow) {
        return '';
      }

      if (row[`${param}ManualMatched`]) {
        return 'manual-match-unselected';
      }
      return 'manual-match-selected';
    },
    getUserSingleManualMatchClass(row, param) {
      if (this.reviewStatus === this.reviewStatuses.reviewed || this.reviewStatus === this.reviewStatuses.ignored || row.matchStatus === this.matchStatuses.newRow) {
        return '';
      }

      if (row[`${param}ManualMatched`]) {
        return 'manual-match-selected';
      }
      return 'manual-match-unselected';
    },
    getParamMatchClass(row, param) {
      if (row.matchStatus === this.matchStatuses.newRow) {
        return 'event-compare-new';
      }

      if (!row.publicStaffCulpability) {
        return '';
      }
      if (!row.userStaffCulpability) {
        return '';
      }
      if (!row.userStaffCulpability[param] && !row.publicStaffCulpability[param]) {
        return '';
      }
      if (row.userStaffCulpability[param] !== row.publicStaffCulpability[param]) {
        return 'event-compare-conflict';
      }

      return '';
    },
    // eslint-disable-next-line no-unused-vars
    canSingleManualMatch(row, param) {
      if (row.reviewStatus === this.reviewStatuses.reviewed || row.reviewStatus === this.reviewStatuses.ignored) {
        return false;
      }
      if (!row.publicStaffCulpability) {
        return false;
      }
      if (!row.userStaffCulpability) {
        return false;
      }
      return true; // row.publicEventCard[param] !== row.userEventCard[param];
    },
    applySingleManualMatch(row, param) {
      if (!this.canSingleManualMatch(row, param)) {
        return;
      }

      if (row[`${param}ManualMatched`]) {
        return;
      }

      // сохраняем данные чтобы можно было отменить действие
      if (!row.backupData) {
        row.backupData = [];
      }
      // row.backupData[param] = cloneDeep(row.publicStaffCulpability[param]);

      if (param === 'staffCategoryCode') {
        this.$set(row.backupData, 'staffCategoryCode', row.publicStaffCulpability.staffCategoryCode);
        this.$set(row.backupData, 'staffCategoryId', row.publicStaffCulpability.staffCategoryId);

        row.publicStaffCulpability.staffCategoryCode = row.userStaffCulpability.staffCategoryCode;
        row.publicStaffCulpability.staffCategoryId = row.userStaffCulpability.staffCategoryId;
      }
      if (param === 'organizationName') {
        this.$set(row.backupData, 'organizationName', row.publicStaffCulpability.organizationName);
        this.$set(row.backupData, 'organizationId', row.publicStaffCulpability.organizationId);

        row.publicStaffCulpability.organizationName = row.userStaffCulpability.organizationName;
        row.publicStaffCulpability.organizationId = row.userStaffCulpability.organizationId;
      }

      this.$set(row, `${param}ManualMatched`, true);
      this.updateRowMatchStatus(row);
      this.applyFilter();
    },
    denySingleManualMatch(row, param) {
      if (!this.canSingleManualMatch(row, param)) {
        return;
      }

      if (!row[`${param}ManualMatched`]) {
        return;
      }

      if (!row.backupData || !row.backupData[param]) {
        return;
      }

      if (param === 'staffCategoryCode') {
        row.publicStaffCulpability.staffCategoryCode = row.backupData.staffCategoryCode;
        row.publicStaffCulpability.staffCategoryId = row.backupData.staffCategoryId;

        row.backupData.staffCategoryCode = null;
        row.backupData.staffCategoryId = null;
      }

      if (param === 'organizationName') {
        row.publicStaffCulpability.organizationName = row.backupData.organizationName;
        row.publicStaffCulpability.organizationId = row.backupData.organizationId;

        row.backupData.organizationName = null;
        row.backupData.organizationId = null;
      }

      this.$set(row, `${param}ManualMatched`, false);
      this.updateRowMatchStatus(row);
      this.applyFilter();
    },
    matchManuallyDisabled() {
      if (this.reviewStatus !== this.reviewStatuses.notReviewed) {
        return true;
      }
      if (this.selectedItems.length !== 2) {
        return true;
      }
      const userItem = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.newRow);
      const publicItem = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.divergence);

      if (!userItem || !userItem.userStaffCulpability || !publicItem || !publicItem.publicStaffCulpability) return true;

      return false;
    },
    matchManually() {
      if (this.selectedItems.length !== 2) {
        return;
      }

      const userItem = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.newRow);
      const publicItem = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.divergence);

      if (!userItem || !userItem.userStaffCulpability || !publicItem || !publicItem.publicStaffCulpability) return;

      const matchStatus = (
        publicItem.publicStaffCulpability.staffCategoryId !== userItem.userStaffCulpability.staffCategoryId
        || publicItem.publicStaffCulpability.organizationId !== userItem.userStaffCulpability.organizationId
      ) ? this.matchStatuses.conflict : this.matchStatuses.success;

      const newItem = {
        userStaffCulpability: userItem.userStaffCulpability,
        publicStaffCulpability: publicItem.publicStaffCulpability,
        matchStatus,
        reviewStatus: this.reviewStatuses.notReviewed
      };

      const userIdx = this.items.findIndex((t) => t === userItem);
      this.items.splice(userIdx, 1);

      const publicIdx = this.items.findIndex((t) => t === publicItem);
      this.items.splice(publicIdx, 1);

      const newIdx = userIdx <= publicIdx ? userIdx : publicIdx;
      this.items.splice(newIdx, 0, newItem);

      this.$emit('match-status-changed', this.recalculateMatchStatus(this.items));

      this.applyFilter();
    },
    breakMappingDisabled() {
      if (this.reviewStatus !== this.reviewStatuses.notReviewed) {
        return true;
      }

      if (this.selectedItems.length === 0) {
        return true;
      }

      const checkStatuses = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.conflict || t.matchStatus === this.matchStatuses.success);
      if (!checkStatuses) return true;

      return false;
    },
    breakMapping() {
      /* Разрыв сопоставления возможен для карточек, имеющих состояние: «Успешно», «Конфликт» */
      if (this.selectedItems.length === 0) {
        return;
      }

      this.selectedItems.forEach((selection) => {
        const baseItem = this.items.find((i) => i === selection);
        if (!baseItem || !(baseItem.matchStatus === this.matchStatuses.conflict || baseItem.matchStatus === this.matchStatuses.success)) {
          return;
        }
        const idx = this.items.findIndex((i) => i === baseItem);

        const userItem = cloneDeep(this.items[idx]);
        const publicItem = cloneDeep(this.items[idx]);

        // одной записи очистить карточку юзера, а другой - карточку паблик
        userItem.userStaffCulpability = null;
        userItem.matchStatus = this.matchStatuses.divergence;

        publicItem.publicStaffCulpability = null;
        publicItem.matchStatus = this.matchStatuses.newRow;

        // this.items.splice(itemIndex, 0, publicItem);
        this.items.splice(idx, 1);
        this.items.splice(idx, 0, userItem);
        this.items.splice(idx, 0, publicItem);
      });

      this.$emit('match-status-changed', this.recalculateMatchStatus(this.items));

      this.applyFilter();
    },
    ignoreMappingDisabled() {
      if (this.reviewStatus !== this.reviewStatuses.notReviewed) {
        return true;
      }

      if (this.selectedItems.length === 0) {
        return true;
      }

      return false;
    },
    ignoreMapping() {
      this.selectedItems.forEach((selection) => {
        if (selection.reviewStatus === this.reviewStatuses.notReviewed) {
          this.$set(selection, 'reviewStatus', this.reviewStatuses.ignoredNotAccepted);
        } else if (selection.reviewStatus === this.reviewStatuses.ignoredNotAccepted) {
          this.$set(selection, 'reviewStatus', this.reviewStatuses.notReviewed);
        }
      });

      if (this.selectedItems.every((t) => t.reviewStatus === this.reviewStatuses.ignoredNotAccepted)) {
        this.$emit('all-ignore-handle');
      }
      if (this.selectedItems.every((t) => t.reviewStatus === this.reviewStatuses.notReviewed)) {
        this.$emit('not-all-ignore-handle');
      }
    },
    updateRowMatchStatus(row) {
      if (!row.publicStaffCulpability) {
        return;
      }
      if (!row.userStaffCulpability) {
        return;
      }

      const matchStatus = (
        row.publicStaffCulpability.staffCategoryId !== row.userStaffCulpability.staffCategoryId
        || row.publicStaffCulpability.organizationId !== row.userStaffCulpability.organizationId
      ) ? this.matchStatuses.conflict : this.matchStatuses.success;

      row.matchStatus = matchStatus;
      this.$emit('match-status-changed', this.recalculateMatchStatus(this.items));
    },
  }
};
</script>

<style scoped lang="scss">
svg {
  path{
    stroke: #e6a23c;
  }
}
.el-table {
  // transition: all ease 0.2s;
}

.el-table--border {
  border-bottom: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
.el-table:before {
  height: 0px;
}
.el-table:after {
  width: 0px;
}
</style>

