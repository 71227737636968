import { render, staticRenderFns } from "./DuplicatesModal.vue?vue&type=template&id=660f638c&scoped=true"
import script from "./DuplicatesModal.vue?vue&type=script&lang=js"
export * from "./DuplicatesModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660f638c",
  null
  
)

export default component.exports