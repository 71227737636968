import lj from 'lodash-joins';

export default {
  data() {
    return {
      matchStatuses: {
        undef: 1, divergence: 2, conflict: 3, newRow: 4, success: 5
      },
      reviewStatuses: {
        notReviewed: 1, reviewed: 2, ignored: 3, ignoredNotAccepted: 10
      },
    };
  },
  methods: {
    getClassByMatchStatus(status) {
      if (status === this.matchStatuses.divergence) return 'event-compare-divergence';
      else if (status === this.matchStatuses.newRow) return 'event-compare-new';
      else if (status === this.matchStatuses.conflict) return 'event-compare-conflict';
      else if (status === this.matchStatuses.success) return 'event-compare-success';
      return '';
    },
    getTextByMatchStatus(status) {
      if (status === this.matchStatuses.divergence) return 'Расхождение';
      else if (status === this.matchStatuses.newRow) return 'Новое';
      else if (status === this.matchStatuses.conflict) return 'Конфликт';
      else if (status === this.matchStatuses.success) return 'Успешно';
      return '?';
    },
    getRowClassName({ row }) {
      return this.getClassByReviewStatus(row.reviewStatus);
    },
    getClassByReviewStatus(status) {
      if (status === this.reviewStatuses.ignoredNotAccepted) {
        return  'event-compare-ignored';
      }
      if (status === this.reviewStatuses.ignored) {
        return  'event-compare-ign-reviewed';
      }
      if (status === this.reviewStatuses.reviewed) {
        return  'event-compare-reviewed';
      }

      return '';
    },
    generateCulpabilityAccessor(obj) {
       return obj.staffCulpabilityId;
    },
    staffCulpabilityAccessor(obj) {
      return obj.accessor;
    },
    fullJoinStaffCulpabilities(userStaffCulpabilities, publicStaffCulpabilities) {
      const userStaffs = userStaffCulpabilities.map((t) => ({
        accessor: this.generateCulpabilityAccessor(t),
        userStaffCulpability: t
      }));
      const publicStaffs = publicStaffCulpabilities.map((t) => ({
        accessor: this.generateCulpabilityAccessor(t),
        publicStaffCulpability: t
      }));
      const staffCulpabilityMatchList = lj.nestedLoopFullOuterJoin(userStaffs, this.staffCulpabilityAccessor,
        publicStaffs, this.staffCulpabilityAccessor);

      staffCulpabilityMatchList.forEach((stCulpMatch) => {
        // статус сопоставления
        if (!stCulpMatch.publicStaffCulpability) stCulpMatch.matchStatus = this.matchStatuses.newRow;
        else if (!stCulpMatch.userStaffCulpability) stCulpMatch.matchStatus = this.matchStatuses.divergence;
        else if (stCulpMatch.publicStaffCulpability && stCulpMatch.userStaffCulpability) {
          // Success если совпадает по всем полям, иначе конфликт
          if (
            stCulpMatch.publicStaffCulpability.staffCategoryId !== stCulpMatch.userStaffCulpability.staffCategoryId
            || stCulpMatch.publicStaffCulpability.organizationId !== stCulpMatch.userStaffCulpability.organizationId
          ) stCulpMatch.matchStatus = this.matchStatuses.conflict;
          else {
            stCulpMatch.matchStatus = this.matchStatuses.success;
          }
        } else {
          stCulpMatch.matchStatus = this.matchStatuses.undefined;
        }

        // статус рассмотрения?
        stCulpMatch.reviewStatus = this.reviewStatuses.notReviewed;
      });

      return staffCulpabilityMatchList;
    },
    сalculateStaffCulpabilityMatchStatus(staffCulpabilityMatchList) {
      if (!staffCulpabilityMatchList || staffCulpabilityMatchList.length === 0) return this.matchStatuses.undefined;

      if (staffCulpabilityMatchList.some((t) => t.matchStatus === this.matchStatuses.divergence)) return this.matchStatuses.divergence;

      if (staffCulpabilityMatchList.some((t) => t.matchStatus === this.matchStatuses.conflict)) return this.matchStatuses.conflict;

      if (staffCulpabilityMatchList.some((t) => t.matchStatus === this.matchStatuses.newRow)) return this.matchStatuses.newRow;

      if (staffCulpabilityMatchList.some((t) => t.matchStatus === this.matchStatuses.success)) return this.matchStatuses.success;

      return this.matchStatuses.undefined;
    },
    technicalReasonsAccessor(obj) {
      return obj.technicalReasonId;
    },
    fullJoinTechnicalReasons(userTechnicalReasons, publicTechnicalReasons) {
      const userTechs = userTechnicalReasons.map((t) => ({
        technicalReasonId: t.technicalReasonId,
        userTechnicalReasonText: t.technicalReasonCode
      }));
      const publicTechs = publicTechnicalReasons.map((t) => ({
        technicalReasonId: t.technicalReasonId,
        publicTechnicalReasonText: t.technicalReasonCode
      }));
      const technicalReasonsMatchList = lj.nestedLoopFullOuterJoin(userTechs, this.technicalReasonsAccessor,
        publicTechs, this.technicalReasonsAccessor);

      technicalReasonsMatchList.forEach((match) => {
        if (!match.userTechnicalReasonText) match.userTechnicalReasonText = null;
        if (!match.publicTechnicalReasonText) match.publicTechnicalReasonText = null;
        match.isMatch = match.userTechnicalReasonText === match.publicTechnicalReasonText;
      });

      return technicalReasonsMatchList;
    },
    cardInformationAccessor(obj) {
      return obj.cardInformationId;
    },
    fullJoinCardInformation(userCardInformation, publicCardInformation) {
      const userInfo = userCardInformation.map((t) => ({
        cardInformationId: t.cardInformationId,
        userCardInformationText: t.cardInformationName
      }));
      const publicInfo = publicCardInformation.map((t) => ({
        cardInformationId: t.cardInformationId,
        publicCardInformationText: t.cardInformationName
      }));
      const cardInformationMatchList = lj.nestedLoopFullOuterJoin(userInfo, this.cardInformationAccessor,
          publicInfo, this.cardInformationAccessor);

      cardInformationMatchList.forEach((match) => {
        if (!match.userCardInformationText) match.userCardInformationText = null;
        if (!match.publicCardInformationText) match.publicCardInformationText = null;
        match.isMatch = match.userCardInformationText === match.publicCardInformationText;
      });

      return cardInformationMatchList;
    },
    recalculateMatchStatus(items) {
      // const matchStatus = this.matchStatuses.undefined;

      if (items.length === 0) {
        return this.matchStatuses.undefined;
      }

      if (items.some((t) => t.matchStatus === this.matchStatuses.divergence)) {
        return this.matchStatuses.divergence;
      }

      if (items.some((t) => t.matchStatus === this.matchStatuses.conflict)) {
        return this.matchStatuses.conflict;
      }

      if (items.some((t) => t.matchStatus === this.matchStatuses.newRow)) {
        return this.matchStatuses.newRow;
      }

      if (items.some((t) => t.matchStatus === this.matchStatuses.success)) {
        return this.matchStatuses.success;
      }

      return this.matchStatuses.undefined;
    }
  }
};
