import axios from 'axios';

export default {
    getEventCard: (id) => axios.get('/api/Events/GetEventCard/', { params: { id } }),
    getEventsWithPagination: (params) => axios.post('/api/Events/GetEventsWithPagination', params),
    getEventsByIncident(incidentId, showDeleted) {
      return axios.get('/api/Events/GetEventsByIncident', { params: { incidentId, showDeleted } });
    },
    delete(id) { return axios.delete('/api/Events/Delete/', { params: { id } }); },
    restore(id) { return axios.delete('/api/Events/Restore/', { params: { id } }); },
    updateEvent: (event) => axios.put(`/api/Events/UpdateEvent/${event.id}`, event),
    updateEventIncidentId: (item) => axios.put(`/api/Events/UpdateEventIncidentId/${item.eventId}`, item),
    createEventByIncident: (eventCard) => axios.post('/api/Events/CreateEventByIncident', eventCard),
};
